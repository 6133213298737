body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* select pitch modal css */

.pitch-modal{
  width: 100% !important;
  max-width: 675px !important;
}

.pitch-modal-header{
  font-size: 19px !important;
  font-weight: 700 !important;
  text-align: center;
}

.pitch-modal-body{
  padding: 0px 62px 27px 62px !important;
}

.pitch-list-hover{
  transition: all 240ms ease-in;
}

.pitch-list-hover:hover{
  border: 1px solid #8B5971 !important;
    border-radius: 5px !important;
    background: #8b59710d !important;
}

.pitch-conform-modal{
  width: 100% !important;
  max-width: 331px !important;
  padding: 25px !important;
}

.pitch-conform-modal-header{
  font-size: 19px !important;
  font-weight: 700 !important;
  text-align: center;
  padding-bottom: 0px !important;
}

.pitch-conform-modal-body{
  padding: 0px !important;
}

@media screen and (max-width: 992px) {
  .pitch-modal-body{
    padding: 0px 19px 30px 19px !important;
  }
  .pitch-modal{
    margin: auto 30px !important;
  }
  .pitch-conform-modal{
    top: 14% !important;
  }
}